<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      light
      max-width="900px"
    >
      <v-card
        :class="{ 'grey lighten-2': dragover }"
        @drop.prevent="onDrop($event)"
        @dragover.prevent="dragover = true"
        @dragenter.prevent="dragover = true"
        @dragleave.prevent="dragover = false"
      >
        <v-card-title>
          <h2 class="text-center font-weight-medium align-center mt-3 mb-3">
            Upload de Executável
          </h2>
          <h4 class="text-center font-weight-light align-center mt-3 mb-3">
            {{ bucket }}
          </h4>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="send()"
          >
            <v-row>
              <v-col>
                <v-file-input
                  v-model="files"
                  show-size
                  truncate-length="50"
                  :label="files ? 'Arquivo selecionado' : 'Selecione o arquivo ou arraste-o para esta janela'"
                  :rules="fileRules"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="version"
                  label="Versão"
                  placeholder="00.00.00"
                  :rules="versionRules"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#E0115F"
            text
            @click="$emit('update:dialog', false)"
          >
            Cancelar
          </v-btn>
          <v-btn
            :disabled="!(version && files && valid)"
            color="#4CAF50"
            text
            @click="send()"
          >
            Enviar
          </v-btn>
        </v-card-actions>

        <v-snackbar
          v-model="snackbar"
          multi-line
          color="#0277BD"
          :timeout="timeout"
        >
          <v-row
            justify="center"
          >
            <v-col
              cols="12"
            >
              <h3 class="font-weight-medium text-center align-center mt-3 mb-3">
                {{ snackbarText }}
              </h3>
              <v-progress-linear
                v-if="uploading"
                :value="percentCompleted"
                stream
                rounded
                color="#4CAF50"
                height="25"
              >
                <template v-slot="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>
            </v-col>
          </v-row>
        </v-snackbar>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  import axiosSYNC from '../../../../../axios-SYNC'

  export default {
    name: 'UploadExecutables',
    model: {
      prop: 'dialog',
      event: 'update:dialog',
    },
    props: {
      dialog: {
        type: Boolean,
        required: true,
      },
      bucket: {
        type: String,
        required: true,
      },
    },
    data: () => {
      return {
        percentCompleted: 0,
        files: null,
        dragover: false,
        version: '',
        uploading: false,
        snackbar: false,
        snackbarText: '',
        timeout: -1,
        valid: false,
        fileRules: [
          (a) => !a ? 'Campo obrigatório' : true,
          (a) => !a.name.endsWith('.exe') ? 'Arquivo não é um executável' : true,
        ],
        versionRules: [
          (a) => !a ? 'Campo obrigatório' : true,
          (a) => !/\d{2}\.\d{2}\.\d{1,3}/.test(a) ? 'Versão inválida' : true,
        ],
      }
    },
    methods: {
      onDrop (e) {
        this.dragover = false
        if (this.files) this.files = null
        this.files = e.dataTransfer.files[0]
      },
      async send () {
        if (!this.valid) return

        const formData = new FormData()
        formData.append('file', this.files)

        this.snackbar = true
        this.uploading = true
        this.timeout = -1
        this.snackbarText = 'Aguarde, realizando o upload dos arquivos...'

        try {
          const response = await axiosSYNC.post('upload/backup', formData, {
            responseType: 'arraybuffer',
            onUploadProgress: function (progressEvent) {
              this.percentCompleted = progressEvent.loaded * 100 / progressEvent.total
            }.bind(this),
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            params: {
              bucket: this.bucket,
              location: 'executaveis',
            },
          })
          if (response.status !== 200) throw new Error(response.data.message)

          const responseLatestJson = await axiosSYNC.get('/get', {
            params: {
              bucket: this.bucket,
              key: 'executaveis/latest.json',
            },
          })
          let latestJson = responseLatestJson.data

          const fileName = this.files.name.replace('.exe', '').toLowerCase()

          if (!latestJson) latestJson = {}
          if (!latestJson.sistemas) latestJson.sistemas = {}

          if (!latestJson.sistemas[fileName]) {
            latestJson.sistemas[fileName] = {
              files: this.files.name,
              path: this.files.name,
              releaseDate: new Date().toISOString(),
              version: this.version,
            }
          } else {
            latestJson.sistemas[fileName].version = this.version
            latestJson.sistemas[fileName].releaseDate = new Date().toISOString()
          }

          const json = JSON.stringify(latestJson)

          const formData1 = new FormData()
          const blob = new Blob([json], { type: 'text/json' })

          formData1.append('file', blob, 'latest.json')

          const response2 = await axiosSYNC.post('upload/backup', formData1, {
            responseType: 'arraybuffer',
            onUploadProgress: function (progressEvent) {
              this.percentCompleted = progressEvent.loaded * 100 / progressEvent.total
            }.bind(this),
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            params: {
              bucket: this.bucket,
              location: 'executaveis',
            },
          })
          if (response2.status !== 200) throw new Error(response2.data.message)
          this.uploading = false
          this.snackbarText = 'O upload foi concluido!'
          this.timeout = 8000

          this.version = null
          this.files = null

          this.$emit('update:dialog', false)
          this.$emit('reload', false)
        } catch (err) {
          this.timeout = 8000
          this.uploading = false
          this.snackbarText = 'O upload falhou. \n' + err
          console.error('FAILURE!!', err)
        }
      },
    },
  }
</script>
