<template>
  <v-container style="min-height: 90%">
    <base-material-card
      icon="mdi-cog-box"
      class="px-3 py-3"
      color="#ffc219"
      :loading="loading"
      :title="`Cliente: ${customerInfo.name}`"
    >
      <v-row
        class="mb-7 fade-transition"
        justify="end"
        no-gutters
      >
        <v-btn
          text
          outlined
          color="secondary"
          class="mt-3"
          @click="uploadDialog = true"
        >
          Enviar Executável
          <v-divider
            class="mx-4"
            vertical
          />
          <v-icon>mdi-file-upload-outline</v-icon>
        </v-btn>
      </v-row>
      <v-row
        class="mb-7 fade-transition"
        justify="center"
        no-gutters
      >
        <v-col
          class="text-center"
          cols="12"
          md="12"
        >
          <h3>Sistemas</h3>

          <v-data-table
            :headers="systemsHeaders"
            :items="sistemasParsed"
            :items-per-page="99"
            class="elevation-1"
          />
        </v-col>
      </v-row>
    </base-material-card>
    <upload-executables
      v-if="bucket"
      v-model="uploadDialog"
      :bucket="bucket"
      @reload="getExecInfo()"
    />
  </v-container>
</template>

<script>
  import axiosAPI from '../../../axios-API'
  import axiosSync from '../../../axios-SYNC'
  import UploadExecutables from './components/executables/uploadExecutables.vue'
  export default {
    name: 'Executaveis',
    components: {
      UploadExecutables,
    },
    data: () => {
      return {
        customerInfo: {},
        customerId: '',
        latestJson: null,
        sistemasParsed: [],
        uploadDialog: false,
        bucket: null,

        loading: true,

        systemsHeaders: [
          { text: 'Nome', value: 'name' },
          { text: 'Arquivo', value: 'files' },
          { text: 'Versão', value: 'version' },
          { text: 'Data de Upload', value: 'releaseDate' },
          { text: '', value: 'actions', align: 'center', sortable: false },
        ],
      }
    },
    async mounted () {
      try {
        await this.getCustomerInfo()
        await this.getExecInfo()
        this.loading = false
      } catch (err) {
        console.error('Error loading data', err)
      }
    },
    methods: {
      async getCustomerInfo () {
        const customerId = this.$route.query.id
        if (!customerId) {
          console.error('Necessário informar Token!')
          return
        }

        const user = JSON.parse(localStorage.getItem('user'))
        const config = {
          headers: {
            Authorization: 'YqiCC2TYmgD0uJ0CmGB2LZ2ZVyynYoaW06Ad',
            'x-access-token': user.token,
          },
        }

        const response = await axiosAPI.get(`/customers/${customerId}`, config)
        const customer = response.data.result

        if (!customer) {
          console.error('Cliente não encontrado')
          return
        }

        this.customerInfo = customer
      },

      async getExecInfo () {
        const bucket = this.customerInfo.tentacle.bucketAWS
        if (!bucket) {
          console.error('Necessário informar Bucket!')
          return
        }

        this.bucket = bucket

        const response = await axiosSync.get('/get', {
          params: {
            bucket: bucket,
            key: 'executaveis/latest.json',
          },
        })
        this.latestJson = response.data
        this.sistemasParsed = []
        Object.entries(this.latestJson.sistemas).map(item => {
          this.sistemasParsed.push({
            name: item[0],
            ...item[1],
          })
        })
      },
    },
  }
</script>
